import { Platform } from '@angular/cdk/platform';
import { NgClass, NgStyle } from '@angular/common';
import {
    Component,
    HostListener,
    inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Account } from '@app/models/authentication/account.model';
import { ButtonData } from '@app/models/cookies.model';
import { NotificationHeader } from '@app/models/notification-header.model';
import { LoginObservable } from '@app/observables/login.observable';
import { ProfilePipe } from '@app/pipes/profile/profile.pipe';
import { SocketService } from '@app/providers/socket/socket.service';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { WindowsSizeService } from '@app/services/windows-size/windows-size.service';
import { ButtonComponent } from '../button/button.component';
import { NotificationsService } from '@app/providers/notifications/notifications.service';
import { viewError } from '@app/functions/view-error-user.function';
import { ErrorHttp } from '@app/models/error-http.model';
import { AuthenticationService } from '@app/providers/authentication/authentication.service';
import { Dialog } from '@angular/cdk/dialog';
import { ModalQuestionComponent } from '@app/modules/modals/modal-question/modal-question.component';
import { NotificationObservable } from '@app/observables/notification.observable';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [RouterLink, ButtonComponent, NgStyle, NgClass],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    listSubscription: Subscription[];
    notifications: number;
    socket: SocketService | null;
    profilePipe: ProfilePipe;
    public showMobileMenu: boolean = false;
    currentAccount: Account | null = null;

    public buttonData: Object = {
        customClass: 'big-btn-font cookies-button',
        type: 'routerLink',
        rounded: false,
        uppercase: false,
        disabled: false,
    };

    // buttons Login Register data.
    public registerButtonData: ButtonData = {
        title: 'Regístrate',
        style: 'cookieSetting',
        minWidth: '80px',
        link: '/autenticacion/registrarCuenta',
        ...this.buttonData,
    };

    public loginButtonData: ButtonData = {
        title: 'Iniciar sesión',
        style: 'primary',
        minWidth: '80px',
        link: '/autenticacion/iniciarSesion',
        ...this.buttonData,
    };

    public windowWidth?: number;

    private readonly _platform = inject(Platform);
    private readonly authService = inject(SocialAuthService);
    private readonly notificationsService = inject(NotificationsService);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly navigationService = inject(NavigationService);
    private readonly shareInfoService = inject(ShareInfoService);
    private readonly windowsSizeService = inject(WindowsSizeService);
    private readonly loginObservable = inject(LoginObservable);
    private notificationObservable: NotificationObservable = inject(
        NotificationObservable
    );

    constructor() {
        this.socket = null;
        this.profilePipe = new ProfilePipe();

        this.notifications = 0;
        this.listSubscription = [
            new Subscription(),
            new Subscription(),
            new Subscription(),
            new Subscription(),
            new Subscription(),
        ];

        const currenSize = this.windowsSizeService.checkMinScreenSize(580);
        if (currenSize) this.showMobileMenu = false;
    }

    ngOnInit(): void {
        if (!this._platform.isBrowser) return;

        this.subscriptionLogin();
        this.subscriptionNotification();
        this.subscriptionWindowSize();
    }

    ngOnDestroy() {
        if (this.socket && this._platform.isBrowser) this.socket?.disconnect();

        this.listSubscription.forEach(itrSub => {
            itrSub.unsubscribe();
        });
    }

    private subscriptionLogin(): void {
        this.listSubscription[0] = this.loginObservable.data$.subscribe(
            (res: Account | null) => {
                if (!res && this.socket) {
                    this.socket?.disconnect();
                    this.socket = null;
                    this.closeSession();
                    return;
                }

                this.currentAccount = res;
                if (this.currentAccount) this.subscriptionSocket();
                return;
            }
        );
    }

    private subscriptionNotification(): void {
        this.listSubscription[1] = this.shareInfoService.socketUser$.subscribe(
            (res: NotificationHeader) => {
                if (res.typeNotification == 'newExchanger') {
                    this.shareInfoService.notification$.emit({
                        description: res.message,
                        url: res.url,
                    });
                }
                this.notifications = res.generalNotification;
                this.notificationObservable.updateData(res);
            }
        );
    }

    private subscriptionSocket(): void {
        if (!this._platform.isBrowser) return;

        this.socket = new SocketService(this.localStorageService);
        this.listSubscription[2] = this.socket.outEven.subscribe(
            (res: NotificationHeader) => {
                if (res) {
                    this.shareInfoService.socketUser$.emit(res);
                    return;
                }

                this.shareInfoService.snackVicente$.emit({
                    message: 'ERROR DE CONEXIÓN',
                    type: 'error',
                });

                this.closeSession();
            }
        );
    }

    private subscriptionWindowSize(): void {
        this.listSubscription[3] = this.windowsSizeService
            .getWindowResizeObservable()
            .subscribe(width => {
                this.windowWidth = width;
                this.showMobileMenu =
                    this.windowWidth && this.windowWidth > 580 ? true : false;
            });
    }

    get viewUser() {
        return `/profile/${this.currentAccount?._id}`;
    }

    viewNotification(): void {
        this.notificationsService.updateNotificationAll().subscribe(
            () => {
                this.notifications = 0;
                this.navigationService.navigatePage('notificaciones');
            },
            ({ code }: ErrorHttp) => {
                viewError('error', code, this.shareInfoService);
            }
        );
    }

    closeSession(): void {
        if (!this._platform.isBrowser) return;

        this.authService
            .signOut()
            .then(() => {})
            .catch(() => {});

        this.navigationService.navigatePage('autenticacion/iniciarSesion');
    }

    toggleMobileMenu(): void {
        this.showMobileMenu = !this.showMobileMenu;
    }

    isHeaderHidden = false;
    lastScrollTop = 0;
    isHiddenMenu = true;

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const currentScroll =
            window.pageYOffset || document.documentElement.scrollTop;

        if (currentScroll > this.lastScrollTop && currentScroll > 100) {
            // Scroll hacia abajo, ocultar el header
            this.isHeaderHidden = true;
            this.isHiddenMenu = true;
        } else {
            // Scroll hacia arriba, mostrar el header
            this.isHeaderHidden = false;
        }

        this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // Para evitar números negativos en scroll
    }

    public focusOutHideMenu(): void {
        if (this._platform.isBrowser) {
            setTimeout(() => {
                this.isHiddenMenu = true;
            }, 200);
        }
    }

    logOutSubscription = new Subscription();
    private authenticationService = inject(AuthenticationService);
    private dialog = inject(Dialog);

    public launchModalLogOut() {
        const message = '¿Quieres cerrar sesión?';
        const option = 'closeSession';
        const dialogRef = this.dialog.open(ModalQuestionComponent, {
            data: { message, option },
        });
        dialogRef.closed.subscribe((response: any) => {
            if (response != undefined && response != null && response) return;

            this.logOut();
            // this.disabledActionButton = false;
        });
    }

    private logOut() {
        this.authenticationService.logout().subscribe(
            () => {
                this.loginObservable.updateData(null);
            },
            ({ code }) => {
                viewError('error', code, this.shareInfoService);
            }
        );
    }
}
