import { EventEmitter, Injectable, Output } from '@angular/core';
import { Socket } from 'ngx-socket-io';

import { NotificationHeader } from '@app/models/notification-header.model';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SocketService extends Socket {
    @Output() outEven: EventEmitter<any> = new EventEmitter();

    constructor(localStorageService: LocalStorageService) {
        super({
            url: `${environment.PROXY ? '/api/' : environment.API_URL_V1}notification`,
            options: {
                autoConnect: true,
                withCredentials: true,
                extraHeaders: {
                    token: localStorageService.getItem('token', true),
                },
            },
        });

        this.ioSocket.on('error', (res: any) => {
            this.outEven.emit(res);
        });

        this.ioSocket.on('notifications', (res: NotificationHeader) => {
            this.outEven.emit(res);
        });
    }

    override disconnect(_close?: any) {
        this.ioSocket.disconnect();
    }
}
