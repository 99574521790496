import { OPERATION_TITLE_ENUM } from '@app/enums/post-section/operation_title.enum';

export const textsForExchanges = {
    setTitle(type: string): string {
        let title = '';

        switch (type) {
            case 'haveIt':
                title = OPERATION_TITLE_ENUM.haveIt;
                break;
            case 'wantIt':
                title = OPERATION_TITLE_ENUM.wantIt;
                break;
            case 'giveYouAHand':
                title = OPERATION_TITLE_ENUM.giveYouAHand;
                break;
            case 'giveMeAHand':
                title = OPERATION_TITLE_ENUM.giveMeAHand;
                break;
            default:
                title = '';
                break;
        }
        return title;
    },
};
