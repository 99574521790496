import { Component } from '@angular/core';
import { PublicationSwiperSkeletonComponent } from '../publication-swiper-skeleton/publication-swiper-skeleton.component';

@Component({
    selector: 'app-publication-card-skeleton',
    standalone: true,
    imports: [PublicationSwiperSkeletonComponent],
    templateUrl: './publication-card-skeleton.component.html',
    styleUrl: './publication-card-skeleton.component.sass',
})
export class PublicationCardSkeletonComponent {}
