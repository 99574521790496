<section class="d-flex justify-content-center align-items-center">
    <div class="modal text-center">
        <img
            alt="vicente"
            src="assets/icons_new_login/vicente_account_validate_modal.svg" />

        <h2>Necesitamos que verifiques tu cuenta</h2>
        <ul>
            <li>
                ¡Por seguridad! Con tu teléfono nos aseguramos de que tú y solo
                tú eres quien puede entrar a tu cuenta.
            </li>
            <li>
                En caso de olvidar la contraseña, podremos recuperar fácilmente
                tu cuenta por medio de tu teléfono.
            </li>
            <li>
                Además, te mandaremos información importante a tu celular. No te
                preocupes, prometemos que será solo información relevante.
            </li>
        </ul>
        <button class="button_secundario_v2" (click)="dialogRef.close()">
            Entendido
        </button>
    </div>
</section>
