import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-account-validation-modal',
    standalone: true,
    imports: [],
    templateUrl: './account-validation-modal.component.html',
    styleUrl: './account-validation-modal.component.sass',
})
export class AccountValidationModalComponent {
    constructor(public dialogRef: DialogRef<void>) {}
}
