<section class="d-flex justify-content-center align-items-center">
    <div class="modal">
        <img
            (click)="dialogRef?.close()"
            class="img-cross cursor-pointer"
            src="assets/img/icons/cross.svg"
        />
        <h2 class="purple-color">{{ title }}</h2>
        <div class="modal-cookies-notification">
            {{ notificationMessage }}
            <a (click)="dialogRef?.close()" routerLink="privacy/cookies">{{
                exploreMessage
            }}</a>
        </div>
        @for (data of cardModalsData; track data) {
            <div>
                <div class="card-modal-cookies">
                    <strong>{{ data.title }}</strong>
                    <div class="d-flex align-items-center">
                        <p>
                            {{ data.description }}
                        </p>
                        @if (data.hasToggleButton) {
                            <div class="small-spacing"></div>
                        }
                        @if (data.hasToggleButton) {
                            <div class="next">
                                <input
                                    type="checkbox"
                                    [id]="data.toggleName"
                                    [name]="data.toggleName!"
                                    [(ngModel)]="data.rejected"
                                />
                                <label
                                    class="ios-checkbox"
                                    [for]="data.toggleName"
                                ></label>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        <p class="text-center small">
            {{ bottomModalMessage }}
        </p>
        <hr />
        <div class="medium-spacing"></div>
        <div class="d-flex justify-content-center">
            <app-button
                (notify)="saveModalData()"
                [data]="saveButtonData"
            ></app-button>
        </div>
        <div class="medium-spacing"></div>
    </div>
</section>
