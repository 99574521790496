import { Platform } from '@angular/cdk/platform';
import {
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { LoginObservable } from '@app/observables/login.observable';
import { ShareInfoService } from '@app/services/share-info/share-info.service';

import { tap } from 'rxjs/operators';

interface ErrorHttpGlobal {
    status: number;
    error: { en: string; es: string; data: any };
}

/**
 *
 * @param req solicitud HTTP entrante
 * @param next  Manejador HTTP
 * @returns
 */
export const errorsInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
) => {
    /**
     * Observador utilizado para manejar el estado de autenticación del usuario
     * @type{LoginObservable}
     */
    const loginObservable: LoginObservable = inject(LoginObservable);

    /**
     *  Servicio utilizado para determinar si la aplicación se está ejecutando en un navegador.
     * @type{Platform}
     */
    const _platform: Platform = inject(Platform);

    /**
     *  Servicio utilizado para compartir información y mostrar notificaciones (snacks) en la interfaz de usuario
     * @type{ShareInfoService}
     */
    const shareInfoService: ShareInfoService = inject(ShareInfoService);

    /**
     * Si la aplicación no se está ejecutando en un navegador, la solicitud se pasa al siguiente manejador sin realizar ningún procesamiento adicional.
     */
    if (!_platform.isBrowser) return next(req);

    /**
     * Se utiliza next(req).pipe(tap(...)) para interceptar la respuesta
     * Si la respuesta es una instancia de HttpResponse, no se realiza ninguna acción específica
     * Si ocurre un error, se maneja en el bloque error.
     */
    return next(req).pipe(
        tap(
            () => {},
            ({ status, error }) => {
                handleError(error, status);
            }
        )
    );

    /**
     *
     * @param error Error recibido
     * @param status Código de estado de la respuesta HTTP
     * @returns
     */

    function handleError(errorGlobal: ErrorHttpGlobal, status: number): void {
        if (status === 500) {
            /**
             * Cuando el error es 500 muestra mensaje personalizado
             */
            shareInfoService.snackVicente$.emit({
                message: 'Hubo un problema, inténtalo más tarde.',
                type: 'error',
            });

            return;
        }

        /**
         * Cuando el objeto no tiene campos muestra un error desconocido y muestra mensaje al usuario
         */
        if (Object.keys(errorGlobal).length === 0) {
            shareInfoService.snackVicente$.emit({
                message: 'Ha ocurrido un error inesperado',
                type: 'error',
            });
            return;
        }

        if (errorGlobal.status === 4305) {
            loginObservable.updateData(null);

            shareInfoService.snackVicente$.emit({
                message: 'Usuario o contraseña incorrecta',
                type: 'error',
            });

            return;
        }

        /**
         * @typedef {Object} response
         * @property {Number} code
         * @property {String} message
         * @property {Object} data
         */

        /**
         * @type {response}
         */

        // Lanzar un error personalizado
        throw {
            code: errorGlobal.status,
            message: errorGlobal.error?.es,
            data: errorGlobal.error.data,
        };
    }
};
