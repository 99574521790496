<div class="publication-card min-sise-card">
    <div class="head">
        <div class="sv-profile-image-container">
            <a [routerLink]="['/profile/', post.user.userId]">
                <img
                    [src]="homePipe.transform(post.user, 'image-avatar')"
                    alt=""
                    class="bg-rainbow profile-picture" />
            </a>
        </div>
        <div (click)="viewProfile(post.user.userId)" class="user-info">
            <div class="text">
                {{ post.user.info.fullName | titlecase }}
            </div>
            <div class="rating d-flex align-items-center">
                <div class="rating-stars">
                    <img alt="star" src="assets/img/star_yellow.png" />
                </div>
                <div class="rating-level">
                    {{ post.user.info.numberStarts }}
                </div>
                @if (
                    currentAccount && post.user?.userId !== currentAccount?._id
                ) {
                    <span class="location d-flex">
                        <img
                            src="/assets/img/location-green.svg"
                            style="margin-left: 0.5rem; width: 0.5rem"
                            alt="location" />
                        <div class="extra-small-spacing-2"></div>
                        <span class="distance">{{ showDistance() }}</span>
                    </span>
                }
            </div>
        </div>

        @if (post.user?.userId !== currentAccount?._id) {
            <a
                (click)="post.follower ? sendUnfollow() : sendFollower()"
                [class]="
                    (post.follower ? 'follow-button-1 ' : 'follow-button-2 ') +
                    'cursor-pointer'
                "
                >{{ post.follower ? 'Siguiendo' : 'Seguir' }}</a
            >
        }
    </div>

    <div class="carousel">
        @defer (on viewport) {
            <app-box-carousel-post
                (loadingData)="post.loading = false"
                [dataSourceFilter]="post.list"
                [owner]="post.user"
                interaction="route">
            </app-box-carousel-post>
        } @placeholder {
            <app-box-carousel-post
                (loadingData)="post.loading = false"
                [dataSourceFilter]="post.list"
                [owner]="post.user"
                interaction="route">
            </app-box-carousel-post>
        } @loading (minimum 50ms) {
            <app-publication-swiper-skeleton></app-publication-swiper-skeleton>
        }
    </div>

    <div class="container-share-link">
        <div class="home-share-link">
            <app-share-link
                [border]="false"
                [documentId]="post.id"
                [icons]="post.icons"
                [type]="'home'"></app-share-link>
        </div>
    </div>
</div>
