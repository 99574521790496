import { NgClass } from '@angular/common';
import { Component, inject, Input } from '@angular/core';

import Card from '@app/models/post-section/card-rectangle.model';
import { NavigationService } from '@app/services/navigation/navigation.service';

@Component({
    selector: 'app-card-rectangle',
    standalone: true,
    imports: [NgClass],
    templateUrl: './card-rectangle.component.html',
    styleUrl: './card-rectangle.component.sass',
})
export class CardRectangleComponent {
    @Input() productsOrService!: Card;
    private readonly navigationService = inject(NavigationService);

    openPublication(_id: string) {
        this.navigationService.navigatePage(`publicacion/${_id}`);
    }
}
