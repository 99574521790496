import { OPERATION_BTN_TITLE_ENUM } from '@app/enums/post-section/operation_btn_title.enum';
import { OPERATION_COLOR_ENUM } from '@app/enums/post-section/operation_color.enum';
import { OPERATION_ICON_ENUM } from '@app/enums/post-section/operation_icon.enum';
import { OPERATION_ID_ENUM } from '@app/enums/post-section/operation_id.enum';
import { OPERATION_NAME_ENUM } from '@app/enums/post-section/operation_name.enum';
import { OPERATION_TITLE_ENUM } from '@app/enums/post-section/operation_title.enum';
import { OPERATION_TYPE_ENUM } from '@app/enums/post-section/operation_type.enum';
import { OPERATION_URL_ENUM } from '@app/enums/post-section/operation_url.enum';
import { OperationConceptView } from '@app/models/post-section/operation-concept-view.model';
import { Operation } from '@app/models/post-section/operation.model';
import { OPERATION_PATTERN } from '@app/types/type_pattern.type';

export function conceptView(name: string): OperationConceptView {
    switch (name) {
        case 'haveIt':
            {
                return {
                    id: OPERATION_ID_ENUM.haveIt,
                    url: OPERATION_URL_ENUM.haveIt,
                    title: OPERATION_TITLE_ENUM.haveIt,
                    btnTitle: OPERATION_BTN_TITLE_ENUM.haveIt,
                    backgroundColor: OPERATION_COLOR_ENUM.haveIt1,
                    typeButton: OPERATION_TYPE_ENUM.haveIt,
                    icon: OPERATION_ICON_ENUM.haveIt,
                };
            }
            break;
        case 'wantIt':
            {
                return {
                    id: OPERATION_ID_ENUM.wantIt,
                    url: OPERATION_URL_ENUM.wantIt,
                    title: OPERATION_TITLE_ENUM.wantIt,
                    btnTitle: OPERATION_BTN_TITLE_ENUM.wantIt,
                    backgroundColor: OPERATION_COLOR_ENUM.wantIt1,
                    typeButton: OPERATION_TYPE_ENUM.wantIt,
                    icon: OPERATION_ICON_ENUM.wantIt,
                };
            }
            break;
        case 'giveYouAHand':
            {
                return {
                    id: OPERATION_ID_ENUM.giveYouAHand,
                    url: OPERATION_URL_ENUM.giveYouAHand,
                    title: OPERATION_TITLE_ENUM.giveYouAHand,
                    btnTitle: OPERATION_BTN_TITLE_ENUM.giveYouAHand,
                    backgroundColor: OPERATION_COLOR_ENUM.giveYouAHand1,
                    typeButton: OPERATION_TYPE_ENUM.giveYouAHand,
                    icon: OPERATION_ICON_ENUM.giveYouAHand,
                };
            }
            break;
        case 'giveMeAHand':
            {
                return {
                    id: OPERATION_ID_ENUM.giveMeAHand,
                    url: OPERATION_URL_ENUM.giveMeAHand,
                    title: OPERATION_TITLE_ENUM.giveMeAHand,
                    btnTitle: OPERATION_BTN_TITLE_ENUM.giveMeAHand,
                    backgroundColor: OPERATION_COLOR_ENUM.giveMeAHand1,
                    typeButton: OPERATION_TYPE_ENUM.giveMeAHand,
                    icon: OPERATION_ICON_ENUM.giveMeAHand,
                };
            }
            break;
    }

    return {
        id: OPERATION_ID_ENUM.match,
        url: OPERATION_URL_ENUM.match,
        title: OPERATION_TITLE_ENUM.match,
        btnTitle: OPERATION_BTN_TITLE_ENUM.match,
        backgroundColor: OPERATION_COLOR_ENUM.match1,
        typeButton: OPERATION_TYPE_ENUM.match,
        icon: OPERATION_ICON_ENUM.match,
    };
}

export function initialData(
    have: any[] = [],
    what: any[] = [],
    giveMe: any[] = [],
    giveYou: any[] = []
): Operation[] {
    return [
        {
            id: OPERATION_ID_ENUM.haveIt,
            case: 3,
            title: OPERATION_TITLE_ENUM.haveIt,
            color: OPERATION_COLOR_ENUM.haveIt2,
            backgroundColor: OPERATION_COLOR_ENUM.haveIt3,
            image: OPERATION_ICON_ENUM.haveIt,
            operation: OPERATION_NAME_ENUM.haveIt,
            description: 'Aquí va lo que ofreces',
            placeholder: 'Agrega la chamba o cosa que ofreces',
            dataSource: have,
        },
        {
            id: OPERATION_ID_ENUM.wantIt,
            case: 3,
            title: OPERATION_TITLE_ENUM.wantIt,
            color: OPERATION_COLOR_ENUM.wantIt2,
            backgroundColor: OPERATION_COLOR_ENUM.wantIt3,
            image: OPERATION_ICON_ENUM.wantIt,
            operation: OPERATION_NAME_ENUM.wantIt,
            description: 'Aquí va lo que buscas o necesitas',
            placeholder: 'Agrega la chamba o cosa que necesitas',
            dataSource: what,
        },
        {
            id: OPERATION_ID_ENUM.giveYouAHand,
            case: 3,
            title: OPERATION_TITLE_ENUM.giveYouAHand,
            color: OPERATION_COLOR_ENUM.giveYouAHand2,
            backgroundColor: OPERATION_COLOR_ENUM.giveYouAHand3,
            image: OPERATION_ICON_ENUM.giveYouAHand,
            operation: OPERATION_NAME_ENUM.giveYouAHand,
            description: 'Aquí va lo que das sin nada a cambio',
            placeholder: 'Agrega la chamba o cosa que ofreces',
            dataSource: giveYou,
        },
        {
            id: OPERATION_ID_ENUM.giveMeAHand,
            case: 3,
            title: OPERATION_TITLE_ENUM.giveMeAHand,
            color: OPERATION_COLOR_ENUM.giveMeAHand2,
            backgroundColor: OPERATION_COLOR_ENUM.giveMeAHand3,
            image: OPERATION_ICON_ENUM.giveMeAHand,
            operation: OPERATION_NAME_ENUM.giveMeAHand,
            description: 'Aquí va lo que necesitas sin dar nada a cambio',
            placeholder: 'Agrega la chamba o cosa que necesitas',
            dataSource: giveMe,
        },
    ];
}

export function initialDataP(
    pattern: OPERATION_PATTERN,
    color: OPERATION_COLOR_ENUM
): Operation[] {
    return [
        {
            id: OPERATION_ID_ENUM.haveIt,
            case: pattern,
            title: OPERATION_TITLE_ENUM.haveIt,
            color: OPERATION_COLOR_ENUM.haveIt2,
            backgroundColor: color,
            image: OPERATION_ICON_ENUM.haveIt,
            operation: OPERATION_NAME_ENUM.haveIt,
            description: 'Aquí va lo que ofreces',
            placeholder: 'Agrega la chamba o cosa que ofreces',
            dataSource: [],
        },
        {
            id: OPERATION_ID_ENUM.wantIt,
            case: pattern,
            title: OPERATION_TITLE_ENUM.wantIt,
            color: OPERATION_COLOR_ENUM.wantIt2,
            backgroundColor: color,
            image: OPERATION_ICON_ENUM.wantIt,
            operation: OPERATION_NAME_ENUM.wantIt,
            description: 'Aquí va lo que buscas o necesitas',
            placeholder: 'Agrega la chamba o cosa que necesitas',
            dataSource: [],
        },
        {
            id: OPERATION_ID_ENUM.giveYouAHand,
            case: pattern,
            title: OPERATION_TITLE_ENUM.giveYouAHand,
            color: OPERATION_COLOR_ENUM.giveYouAHand2,
            backgroundColor: color,
            image: OPERATION_ICON_ENUM.giveYouAHand,
            operation: OPERATION_NAME_ENUM.giveYouAHand,
            description: 'Aquí va lo que das sin nada a cambio',
            placeholder: 'Agrega la chamba o cosa que ofreces',
            dataSource: [],
        },
        {
            id: OPERATION_ID_ENUM.giveMeAHand,
            case: pattern,
            title: OPERATION_TITLE_ENUM.giveMeAHand,
            color: OPERATION_COLOR_ENUM.giveMeAHand2,
            backgroundColor: color,
            image: OPERATION_ICON_ENUM.giveMeAHand,
            operation: OPERATION_NAME_ENUM.giveMeAHand,
            description: 'Aquí va lo que necesitas sin dar nada a cambio',
            placeholder: 'Agrega la chamba o cosa que necesitas',
            dataSource: [],
        },
    ];
}

export function searchOneData(
    option: OPERATION_NAME_ENUM,
    dataSource: any[] = []
): Operation {
    switch (option) {
        case 'haveIt':
            {
                return {
                    id: OPERATION_ID_ENUM.haveIt,
                    case: 3,
                    title: OPERATION_TITLE_ENUM.haveIt,
                    color: OPERATION_COLOR_ENUM.haveIt2,
                    backgroundColor: OPERATION_COLOR_ENUM.haveIt3,
                    image: OPERATION_ICON_ENUM.haveIt,
                    operation: OPERATION_NAME_ENUM.haveIt,
                    description: 'Aquí va lo que ofreces',
                    placeholder: 'Agrega la chamba o cosa que ofreces',
                    dataSource,
                };
            }
            break;

        case 'wantIt':
            {
                return {
                    id: OPERATION_ID_ENUM.wantIt,
                    case: 3,
                    title: OPERATION_TITLE_ENUM.wantIt,
                    color: OPERATION_COLOR_ENUM.wantIt2,
                    backgroundColor: OPERATION_COLOR_ENUM.wantIt3,
                    image: OPERATION_ICON_ENUM.wantIt,
                    operation: OPERATION_NAME_ENUM.wantIt,
                    description: 'Aquí va lo que buscas o necesitas',
                    placeholder: 'Agrega la chamba o cosa que necesitas',
                    dataSource,
                };
            }
            break;

        case 'giveYouAHand':
            {
                return {
                    id: OPERATION_ID_ENUM.giveYouAHand,
                    case: 3,
                    title: OPERATION_TITLE_ENUM.giveYouAHand,
                    color: OPERATION_COLOR_ENUM.giveYouAHand2,
                    backgroundColor: OPERATION_COLOR_ENUM.giveYouAHand3,
                    image: OPERATION_ICON_ENUM.giveYouAHand,
                    operation: OPERATION_NAME_ENUM.giveYouAHand,
                    description: 'Aquí va lo que das sin nada a cambio',
                    placeholder: 'Agrega la chamba o cosa que ofreces',
                    dataSource,
                };
            }
            break;

        case 'giveMeAHand':
            {
                return {
                    id: OPERATION_ID_ENUM.giveMeAHand,
                    case: 3,
                    title: OPERATION_TITLE_ENUM.giveMeAHand,
                    color: OPERATION_COLOR_ENUM.giveMeAHand2,
                    backgroundColor: OPERATION_COLOR_ENUM.giveMeAHand3,
                    image: OPERATION_ICON_ENUM.giveMeAHand,
                    operation: OPERATION_NAME_ENUM.giveMeAHand,
                    description:
                        'Aquí va lo que necesitas sin dar nada a cambio',
                    placeholder: 'Agrega la chamba o cosa que necesitas',
                    dataSource,
                };
            }
            break;
    }

    return {
        id: OPERATION_ID_ENUM.match,
        case: 3,
        title: OPERATION_TITLE_ENUM.match,
        color: OPERATION_COLOR_ENUM.match1,
        backgroundColor: OPERATION_COLOR_ENUM.match1,
        image: OPERATION_ICON_ENUM.match,
        operation: OPERATION_NAME_ENUM.match,
        description: '',
        placeholder: '',
        dataSource,
    };
}
