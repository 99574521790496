<swiper-container pagination="true" pagination-clickable="true" space-between="50" mousewheel="true" >
    @for (itrData of dataSource; track $index) {
        <swiper-slide lazy="true">
            <div class="postBoxHead" [style.background-color]="itrData.color">
                <img [src]="itrData.image" />
                <p>{{ textsForExchanges.setTitle(itrData.operation) }}</p>
            </div>

            <div
                class="box-post d-flex align-items-center justify-content-center"
                [style.min-height.px]="this.finalSize"
                [class]="itrData.backgroundColor">
                <app-box-post-image
                    [owner]="owner"
                    [whitespace]="true"
                    [interaction]="interaction"
                    [operation]="itrData.operation"
                    [type]="itrData.case"
                    [data]="itrData.dataSource"
                    (maxHeight)="
                        getComponentHeight($event)
                    "></app-box-post-image>
            </div>
        </swiper-slide>
    }
</swiper-container>
