@if (type == 0) {
    <app-box-post-type-one
        [productsOrServices]="productsOrServices"
        (maxHeight)="getComponentHeight($event)"
    ></app-box-post-type-one>
}
@if (type == 1) {
    <app-box-post-type-two
        [productsOrServices]="productsOrServices"
        (maxHeight)="getComponentHeight($event)"
    ></app-box-post-type-two>
}
@if (type == 2) {
    <app-box-post-type-three
        [productsOrServices]="productsOrServices"
        (maxHeight)="getComponentHeight($event)"
    ></app-box-post-type-three>
}
@if (type == 3) {
    <app-box-post-type-four
        [productsOrServices]="productsOrServices"
        (maxHeight)="getComponentHeight($event)"
    ></app-box-post-type-four>
}
