@if (visible) {
    <div class="tip" [@slideInOut]>
        <button class="btn-cross" (click)="visible = false">
            <img
                src="assets/img/icons/cross.svg"
                class="img-cross cursor-pointer" />
        </button>
        <div class="header">
            <img src="assets/vicente/checked.png" alt="Vicente checked icon" />
            <h4>Tip ganador</h4>
        </div>
        <p [innerHTML]="message"></p>
        <button
            class="btn-action green-bg green-bg-hover cursor-pointer"
            (click)="visible = false">
            {{ title_btn }}
        </button>
    </div>
}
