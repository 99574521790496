interface UserInfo {
    fullName: string;
    numberStarts: number;
    picture: string;
    avatar: string;
}

interface UserAddress {
    latitude: string;
    longitude: string;
}

export interface UserModel {
    info: UserInfo;
    address: UserAddress;
    userId: string;
    shareLink: string;
}

export const initUser: UserModel = {
    info: {
        fullName: "",
        numberStarts: 4.6,
        picture: "",
        avatar: ""
    },
    address: {
        latitude: "",
        longitude: ""
    },
    userId: "",
    shareLink: ""
};
