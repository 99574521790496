import { Platform } from '@angular/cdk/platform';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { NavigationService } from '@app/services/navigation/navigation.service';

export const urlSimpleGuard: CanActivateFn = (route, state) => {
    const { url } = route;
    const navigationService = inject(NavigationService);

    if (url.length == 0) {
        navigationService.navigatePage('autenticacion/bienvenida');
        return false;
    }

    return true;
};
