<div
    (click)="openPublication(productsOrService.id)"
    [ngClass]="{ 'image-left': productsOrService.imageAlignLeft }"
    class="card-rectangle-product-or-service"
>
    <div class="content">
        <div
            [ngClass]="{ 'with-image': productsOrService.imageUrl }"
            class="title"
        >
            <p>{{ productsOrService.title }}</p>
        </div>
        @if (productsOrService.imageUrl) {
            <div class="image">
                <img [src]="productsOrService.imageUrl" alt="image" loading="lazy" />
            </div>
        }
    </div>
</div>
