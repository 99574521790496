@defer {
    <div class="carousel-categories">
        <swiper-container
            #swiper_categories
            space-between="15"
            mousewheel="true"
            slides-per-view="auto"
            rewind="true"
            navigation="true"
            class="swiper-categories">
            @for (category of categories; track $index) {
                <swiper-slide>
                    <div class="category" (click)="viewCategory(category)">
                        <img
                            onerror="this.src='assets/img/noimage.svg'"
                            [src]="urlFile + category?.pictures?.mobilePath"
                            alt="category" />
                        <h3
                            [style.background-color]="
                                background(category.classification)
                            ">
                            <span>{{ category.name }}</span>
                        </h3>
                    </div>
                </swiper-slide>
            }
        </swiper-container>
    </div>
} @placeholder {
    <div class="carousel-categories">
        <swiper-container
            #swiper_categories
            space-between="15"
            mousewheel="true"
            slides-per-view="auto"
            rewind="true"
            navigation="true"
            class="swiper-categories">
            @for (category of categories; track $index) {
                <swiper-slide>
                    <div class="category" (click)="viewCategory(category)">
                        <img
                            onerror="this.src='assets/img/noimage.svg'"
                            [src]="urlFile + category?.pictures?.mobilePath"
                            alt="category" />
                        <h3
                            [style.background-color]="
                                background(category.classification)
                            ">
                            <span>{{ category.name }}</span>
                        </h3>
                    </div>
                </swiper-slide>
            }
        </swiper-container>
    </div>
} @loading {
    <app-skeleton-categories-carousel [title]="false" [pagination]="false" />
}
