import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import Card from '@app/models/post-section/card-rectangle.model';
import { environment } from '@environments/environment';
import { BoxPostTypeFourComponent } from './box-post-type-four/box-post-type-four.component';
import { BoxPostTypeOneComponent } from './box-post-type-one/box-post-type-one.component';
import { BoxPostTypeThreeComponent } from './box-post-type-three/box-post-type-three.component';
import { BoxPostTypeTwoComponent } from './box-post-type-two/box-post-type-two.component';

@Component({
    selector: 'app-box-post-image',
    standalone: true,
    imports: [
        BoxPostTypeFourComponent,
        BoxPostTypeThreeComponent,
        BoxPostTypeTwoComponent,
        BoxPostTypeOneComponent,
    ],
    templateUrl: './box-post-image.component.html',
    styleUrl: './box-post-image.component.sass',
})
export class BoxPostImageComponent implements OnInit, AfterViewInit {
    @Input() data!: any;
    @Input() type!: number;
    @Input() owner: any;
    @Input() whitespace: boolean = false;
    @Input() interaction!: string;
    @Input() operation!: string;

    public componentSize: number = 0;

    @Output() maxHeight = new EventEmitter<number>();

    postNumer: number = 0;
    productsOrServices: Card[] = [];

    ngOnInit(): void {
        this.setCardData();
    }

    getComponentHeight($event: number) {
        this.componentSize = $event;
    }

    ngAfterViewInit(): void {
        this.maxHeight.emit(this.componentSize);
    }

    setCardData(): void {
        this.postNumer = this.data.length;

        this.data.forEach((e: any) => {
            let imageUrl: string | null;
            if (e?.general) {
                imageUrl = environment.CATEGORY_FILE + e.picture[0]
            } else {
                imageUrl =
                    e.picture && e.picture.length > 0
                        ? environment.URL_FILE + e.picture[0]
                        : null;

            }

            this.productsOrServices.push({
                title: e.info.title,
                imageUrl: imageUrl,
                imageAlignLeft: true,
                id: e._id,
                interaction: this.interaction,
                owner: this.owner,
                operation: this.operation,
            } as Card);
        });
    }
}
