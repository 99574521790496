import { animate, style, transition, trigger } from '@angular/animations';
import {
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    inject,
} from '@angular/core';
import { ShareInfoService } from '../../services/share-info/share-info.service';
import { Platform } from '@angular/cdk/platform';

@Component({
    selector: 'app-tip',
    standalone: true,
    imports: [],
    templateUrl: './tip.component.html',
    styleUrl: './tip.component.sass',
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ top: '-2rem' }),
                animate('0.3s ease-in-out', style({ top: '2rem' })),
            ]),
            transition(':leave', [
                animate('0.3s ease-in-out', style({ top: '-2rem' })),
            ]),
        ]),
    ],
})
export class TipComponent {
    @HostBinding('@slideInOut') visible: boolean = false;

    message: string = '';
    title_btn: string = '';

    private readonly shareInfoService = inject(ShareInfoService);
    private readonly _platform = inject(Platform);

    constructor(private element: ElementRef) {}

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        // Verifica si el clic ocurrió fuera del elemento
        if (
            this.visible &&
            !this.element.nativeElement.contains(event.target)
        ) {
            this.visible = false;
        }
    }

    ngOnInit() {
        if (!this._platform.isBrowser) return;
        this.subscriptionTip();
    }

    private subscriptionTip(): void {
        this.shareInfoService.tip$.subscribe(({ message, title_btn }) => {
            this.message = message;
            this.title_btn = title_btn;

            if (this.visible) return;

            this.visible = true;
            setTimeout(() => {
                this.visible = false;
            }, 30_000);
        });
    }
}
