import { Dialog } from '@angular/cdk/dialog';
import { TitleCasePipe } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ShareLinkComponent } from '@app/components/share-link/share-link.component';
import { Account } from '@app/models/authentication/account.model';
import { initPost, PostModel } from '@app/models/post-box/post.model';
import { ModalQuestionComponent } from '@app/modules/modals/modal-question/modal-question.component';
import { BoxCarouselPostComponent } from '@app/modules/post-section/box-carousel-post/box-carousel-post.component';
import { HomePipe } from '@app/pipes/home/home.pipe';
import { OpenModalService } from '@app/services/open-modal/open-modal.service';
import { ScrollHomeService } from '@app/services/scroll-home/scroll-home.service';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { PublicationSwiperSkeletonComponent } from '../publication-swiper-skeleton/publication-swiper-skeleton.component';
import { ProfileService } from '@app/providers/profile/profile.service';

@Component({
    selector: 'app-publication-card',
    standalone: true,
    imports: [
        RouterLink,
        BoxCarouselPostComponent,
        ShareLinkComponent,
        TitleCasePipe,
        PublicationSwiperSkeletonComponent,
    ],
    templateUrl: './publication-card.component.html',
    styleUrl: './publication-card.component.sass',
})
export class PublicationCardComponent {
    @Input({ required: true }) public post: PostModel = initPost;
    @Input() public currentAccount: Account | null = null;

    public homePipe = new HomePipe();
    private titlecase = new TitleCasePipe();

    private readonly router = inject(Router);
    private readonly openModalService = inject(OpenModalService);
    private readonly profileService = inject(ProfileService);
    private readonly scrollHomeService = inject(ScrollHomeService);
    private readonly dialog = inject(Dialog);
    private readonly shareInfoService = inject(ShareInfoService);

    constructor() {}

    public viewProfile(userId: string) {
        if (!userId || userId == '') return;

        this.router.navigate(['/profile/', userId]);
    }

    public showDistance() {
        const operatedDistance: string =
            this.post.distance != undefined
                ? String((Number(this.post.distance) / 1000).toFixed(0))
                : ' ';
        return `${operatedDistance} km de ti`;
    }

    public sendFollower(): void {
        if (!this.openModalService.openModalLogin()) return;

        const {
            follower,
            user: { userId },
        } = this.post;

        if (this.currentAccount?._id == userId) return;

        if (follower) return;

        if (this.openModalService.openModalLogin()) {
            this.profileService.sentFollower(userId).subscribe(() => {
                this.scrollHomeService.resetValues();
                this.post.follower = !follower;
            });
        }
    }

    sendUnfollow(): void {
        const message = 'Dejarás de seguir a este usuario';
        const option = 'follower';
        const {
            user: { userId, info },
            follower,
        } = this.post;

        const dialogRef = this.dialog.open(ModalQuestionComponent, {
            data: { message, option },
        });
        dialogRef.closed.subscribe((response: any) => {
            if (
                response != undefined &&
                response != null &&
                response &&
                this.openModalService.openModalLogin()
            ) {
                this.profileService.sentFollower(userId).subscribe(() => {
                    this.scrollHomeService.resetValues();
                    this.post.follower = !follower;

                    this.shareInfoService.snackVicente$.emit({
                        message:
                            'Has dejado de seguir a ' +
                            this.titlecase.transform(info.fullName),
                        type: 'error',
                    });
                });
            }
        });
    }
}
