import { Component } from '@angular/core';

@Component({
    selector: 'app-spinner',
    standalone: true,
    imports: [],
    templateUrl: './spinner.component.html',
    styleUrl: './spinner.component.sass',
})
export class SpinnerComponent {
 
}
