import {
    provideHttpClient,
    withFetch,
    withInterceptors,
} from '@angular/common/http';
import { ApplicationConfig, ErrorHandler } from '@angular/core';
import {
    provideClientHydration,
    withNoHttpTransferCache,
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { environment, googleTagManager } from '@environments/environment';

import { routes } from './app.routes';
import { spinnerInterceptor } from './interceptors/spinner/spinner.interceptor';
import {
    GoogleLoginProvider,
    SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { connectionInterceptor } from './interceptors/connection/connection.interceptor';
import { tokenInterceptor } from './interceptors/token/token.interceptor';
import { errorsInterceptor } from './interceptors/errors/errors.interceptor';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { CustomErrorHandlerService } from './services/custom-error-handler/custom-error-handler.service';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideClientHydration(withNoHttpTransferCache()),
        provideHttpClient(
            withFetch(),
            withInterceptors([
                errorsInterceptor,
                connectionInterceptor,
                // releaseVersionInterceptor,
                tokenInterceptor,
                spinnerInterceptor,
            ])
        ),
        {
            provide: 'googleTagManagerId',
            useValue: googleTagManager.id,
        },
        provideAnimationsAsync(),
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.GOOGLE_CLIENT_ID
                        ),
                    },
                ],
                onError: (err: any) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.RECAPTCHA_PUBLIC,
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandlerService,
        },
    ],
};
