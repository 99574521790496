import { Component } from '@angular/core';

@Component({
  selector: 'app-publication-swiper-skeleton',
  standalone: true,
  imports: [],
  templateUrl: './publication-swiper-skeleton.component.html',
  styleUrl: './publication-swiper-skeleton.component.sass'
})
export class PublicationSwiperSkeletonComponent {

}
