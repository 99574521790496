<div class="container-general">
    <app-header></app-header>
    <div class="content-all">
        <router-outlet></router-outlet>
        @if (validateChat()) {
        <div id="app-global-bottom-spacing"></div>
        }
    </div>
    <footer [ngClass]="{'header-footer': !validateChat()}">
        <app-footer></app-footer>
    </footer>

    <app-cookies-banner></app-cookies-banner>
</div>
