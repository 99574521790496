import { Routes } from '@angular/router';

import { OfflineComponent } from './components/offline/offline.component';
import { PageIpInvalidComponent } from './components/page-ip-invalid/page-ip-invalid.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RootHeaderFooterComponent } from './components/root-header-footer/root-header-footer.component';
import { RootComponent } from './components/root/root.component';
import { loginGuard } from './guards/login/login.guard';
import { securityDevQaGuard } from './guards/securityDevQa/security-dev-qa/security-dev-qa.guard';
import { urlSimpleGuard } from './guards/url-simple/url-simple.guard';
import { HomeComponent } from './modules/home/home/home.component';

export const routes: Routes = [
    {
        path: 'autenticacion',
        canActivate: [securityDevQaGuard],
        loadChildren: () =>
            import('./modules/authentication/authentication.routes').then(
                routes => routes.authenticationRoutes
            ),
    },
    // {
    //     path: 'prehome',
    //     canActivate: [noViewLoginGuard, securityDevQaGuard],
    //     loadChildren: () =>
    //         import('./modules/pre-home/pre-home.routes').then(
    //             routes => routes.preHomeRoutes
    //         ),
    // },
    {
        path: 'privacy',
        canActivate: [securityDevQaGuard],
        loadChildren: () =>
            import('./modules/privacy/privacy.routes').then(
                routes => routes.privacyRoutes
            ),
    },
    {
        path: '',
        component: RootHeaderFooterComponent,
        canActivate: [securityDevQaGuard],
        children: [
            {
                path: '',
                canActivate: [urlSimpleGuard],
                component: RootComponent,
            },
            {
                path: 'home',
                redirectTo: '/inicio',
            },
            {
                path: 'inicio',
                component: HomeComponent,
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('./modules/profile/profile.routes').then(
                        routes => routes.profileRoutes
                    ),
            },
            {
                path: 'campaigns',
                loadChildren: () =>
                    import('./modules/campaign/campaign.routes').then(
                        routes => routes.campaignRoutes
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/search/search.routes').then(
                        routes => routes.searchRoute
                    ),
            },
            {
                path: 'post',
                loadChildren: () =>
                    import('./modules/post-section/post-section.routes').then(
                        routes => routes.postSectionRoutes
                    ),
            },
            {
                path: 'publicacion',
                loadChildren: () =>
                    import('./modules/publications/publications.routes').then(
                        routes => routes.publicationsRoutes
                    ),
            },
            {
                path: 'barter',
                canActivate: [loginGuard],
                loadChildren: () =>
                    import('./modules/barter/barter.routes').then(
                        routes => routes.barterRoutes
                    ),
            },
            {
                path: 'notificaciones',
                canActivate: [loginGuard],
                loadChildren: () =>
                    import('./modules/notifications/notifications.routes').then(
                        routes => routes.notificationsRoutes
                    ),
            },
            {
                path: 'chat',
                loadChildren: () =>
                    import('./modules/chat-match/chat-match.routes').then(
                        routes => routes.chatMatchRoutes
                    ),
            },
        ],
    },
    {
        path: 'fuera_de_region',
        canActivate: [securityDevQaGuard],
        component: PageIpInvalidComponent,
    },
    {
        path: 'mantenimiento',
        canActivate: [securityDevQaGuard],
        component: OfflineComponent,
    },
    {
        path: '**',
        canActivate: [securityDevQaGuard],
        pathMatch: 'full',
        component: PageNotFoundComponent,
    },
];
