import { Platform } from '@angular/cdk/platform';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { catchError, Observable, throwError, timeout } from 'rxjs';

/**
 * Interceptor HTTP que maneja la conexión a internet y muestra notificaciones al usuario.
 *
 * @param {HttpRequest<any>} req - Solicitud HTTP entrante.
 * @param {HttpHandlerFn} next - Manejador HTTP.
 * @returns {Observable<HttpEvent<unknown>>} - Un observable que emite el resultado de la solicitud HTTP.
 */

export const connectionInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    /**
     *  Servicio utilizado para determinar si la aplicación se está ejecutando en un navegador.
     * @type{Platform}
     */

    const _platform = inject(Platform);

    /**
     *  Servicio utilizado para compartir información y mostrar notificaciones (snacks) en la interfaz de usuario
     * @type{ShareInfoService}
     */
    const shareInfoService: ShareInfoService = inject(ShareInfoService);

    /**
     * Si la aplicación no se está ejecutando en un navegador, la solicitud se pasa al siguiente manejador sin realizar ningún procesamiento adicional.
     */
    if (!_platform.isBrowser) return next(req);

    /**
     * Si el navegador detecta que no existe conexión, muestra mensaje y retorna error.
     */
    if (!navigator.onLine) {
        shareInfoService.snackVicente$.emit({
            message: 'Verifica tu conexión a internet',
            type: 'error',
        });
        throw new HttpErrorResponse({ error: 'No Internet Connection' });
    }

    /**
     * Verificar la velocidad de la conexión usando Network Information API
     * Si la velocidad es menor a 1 Mbps muestra mensaje
     */
    if ('connection' in navigator) {
        const connection = (navigator as any).connection;
        if (connection.downlink < 1) {
            shareInfoService.snackVicente$.emit({
                message: 'Tu conexión a internet es lenta',
                type: 'error',
            });
        }
    }

    return next(req).pipe(
        catchError((error: any) => {
            if (error.error.message === 'Failed to fetch') {
                /**
                 * Maneja el error de respuesta HTTP mostrando un mensaje de verificación de conexión.
                 */
                shareInfoService.snackVicente$.emit({
                    message: 'Verifica tu conexión a internet',
                    type: 'error',
                });

                throw error;
            }

            throw error;
        })
    );
};
